.searchContainer{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}

.searchContainer > input{
    max-width: 50%;
    border: 1px solid #c5cfda;
    background-color: rgb(239,239,239);
    border-radius: 4px;
    height: 43px;
    padding: 2px 12px;
}

.addClientMainContainer{
    margin-left: 20px;
    width: 100%;
    position: relative;
}

.addClientContainer{
    position: relative;
    top: -28px;
    width: 100%;
}

.addClientText{
    color: #7c91ab;
    font-family: "ProximaNovaBold";
    font-weight: normal;
    font-size: 1rem;
}

.add-clients-table{
    padding: 0 !important;
}

.selectClient{
    display: flex;
    flex-direction: row;
    height: auto;
    gap: 0.5rem;
}

.selectClient > button {
    white-space: nowrap;
    font-size: 0.9rem;
    border-radius: 4px;
}

#selectInput{
    flex-basis: 100%;
    z-index: 1;
}

#selectInput > div:nth-child(2){
    border-radius: 0;
    height: auto;
    border: 1px solid #c5cfda

}

.hr{
    background-color: rgb(154,154,154);
    position: relative;
    width: 100%;
}


.edit-user-form-section{
    margin-left: -7px !important;
    margin-right: -7px !important;
}

.cta-outer-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.cta-inner-wrapper{
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-left: 48px;
}

.client-detail-info{
    white-space: nowrap;
}

.client-detail-info > thead > th:not(:first-child), .client-detail-info-values > th:not(:first-child){
    text-align: center;
}

.access-all-projects-button-container-tenant-user-tab {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    button {
        padding: 0.1rem 0.5rem;
        background-color: $primary;
        color: white;
    }
}

// media query --------------------------------------------

@media (max-width: 768px){
    .client-detail-info{
        white-space: normal;
    }
}

@media (max-width: 568px){
    .edit-user-form-section > div{
        flex-basis: 100% !important;
        max-width: 100% !important;
    }

    .searchContainer{
        flex-direction: column;
        margin-top: 20px;
    }

    .addClientMainContainer{
        margin-left: 0;
    }

    .addClientContainer{
        top: 0px;
    }

    .searchContainer > input{
        max-width: 100%;
    }

    .cta-inner-wrapper{
        width: 80%;       
    }

    // applies to "Client Table" at the bottom of the page
    // to change "Table Layout to Flex Layout"
    // to support responsive design below 420px
    .add-client-table-head tr{
        display: flex;
        justify-content: space-between;
    }

    .client-basic-info{
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }

    // on header section of "Client Table"
    // to fix alignment of "client name" & "expand and delete" icons respectively
    .client-basic-info > td:first-child{
        padding: 1rem;
    }

    .client-basic-info > td:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 428px){
    .client-detail-info{
        line-height: 1.1;
    }
}
